import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout lang="en">
    <Seo title="productivity - schedule planner and time management" description="The ultimate time tracking app. It will help you track your day and visualize where you're wasting time. Stop procrastinating, be more productive and reach your goals!" lang="en" />
    <Hero locale="en" />
  </Layout>
)

export default IndexPage
